import React from "react";
import Comingsoon from '../../assets/images/comingsoon.png';
import '../../components/Styles/styles.css';


function ComingSoon() {
  return (
      <img src={Comingsoon}  alt="comingsonn"  className="comingsoon"/>
  );
}

export default ComingSoon;
